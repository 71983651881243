<template>
  <PageWithHeader>
    <template #title>
      <t path="tournamentlist_2">Tournament</t>
    </template>
    <div class="my-24 px-12">
      <t as="p" path="tournamentlist_3" class="f-bold" style="line-height: 24px;">Open</t>
      <div class="mt-8">
        <Holder :content="validList">
          <List :list-data="validList" #="{ item }">
            <TheTournamentItem :list="item" />
          </List>
        </Holder>
      </div>
      <t path="tournamentlist_4" class="f-bold mt-24" style="line-height: 24px;">Closed</t>
      <div class="mt-8">
        <Holder :content="closedList">
          <List :list-data="closedList" #="{ item }">
            <TheTournamentItem :list="item" closed />
          </List>
        </Holder>
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { defaultToArray } from '@/common/common'
import { defineComponent, ref } from 'vue'
import TheTournamentItem from '@/modules/membership/tournament/components/TheTournamentItem.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import { readTournamentList, TournamentSchema } from '@/modules/membership/tournament/tournament.api'

export default defineComponent({
  name: 'Tournament',
  components: { List, Holder, PageWithHeader, TheTournamentItem },
  setup () {
    const validList = ref<Array<TournamentSchema> | null>(null)
    const closedList = ref<Array<TournamentSchema> | null>(null)

    readTournamentList({ status: '0,1', page: 1, pageCount: 50 })
      .then(resp => validList.value = defaultToArray(resp) as Array<TournamentSchema>)

    readTournamentList({ status: 2, page: 1, pageCount: 50 })
      .then(resp => closedList.value = defaultToArray(resp) as Array<TournamentSchema>)

    return {
      validList,
      closedList,
    }
  },
})
</script>
<style scoped lang="scss">

</style>
